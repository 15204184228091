import React from 'react';
import LinkedinIcon from './images/linkedinIcon.svg';
import BehanceIcon from './images/behanceIcon.svg';


function Footer() {


  return (
    <>
    <div className='footerContainer'>
    <div className='container-fluid ' >
      <div className='row justify-content-between px-5 my-4'>
        <div className='col-auto'>
        <p className=' text-center m-0'>© 2024 by Erick Gallardo</p>
        </div>
        <div className='col-auto'>
        <a className='footerIcon' href="https://www.linkedin.com/in/erickgallardo/" target="_blank" rel="noreferrer"><img className='me-3' src={LinkedinIcon} alt='linkedinIcon' /> </a>
        <a className='footerIcon' href="https://www.behance.net/ErickGallard0" target="_blank" rel="noreferrer"> <img  src={BehanceIcon} alt='behanceIcon' /></a>
        
        </div>
      </div>
    </div>
    </div>
  
  </>
  )
}

export default Footer
