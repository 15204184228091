import React from 'react'
import ImageMe from './images/erickG.jpg';

function About() {
  return (
    <>
    <div className='container projectView'>
      <div className='row justify-content-center mt-5 pt-lg-5'>
      <div className='col-auto col-lg-5'>
           <img className='img-fluid myPicture' src={ImageMe} alt='aboutMe' />
           <p className='mt-4'>- Based in Puebla, México</p>
        </div>
        <div className='col ps-5 d-flex flex-column justify-content-around'>
   
           {/* <h3 className=' mt-4'>Design. Research. Create. Develop.</h3>*/}
           <div>
           <h1 className='font1'>Erick Gallardo</h1>
           <p className='mt-4'>
             
             Hey there! I'm a designer with a simple mission: think of innovative and creative ideas to make life easier and more enjoyable for people, and then make them happen! I believe creating bonds between users and products through experiences is the key to a successful brand.</p>
                        
                         <p className='mt-4'>
                 
                         </p>
                         <h4 className='fw-light fst-italic mt-4'>        Every challenge is a chance to learn new things and design something awesome!</h4>
           </div>

{/*<p className='mt-4'>I love practicing CrossFit and expressing my creativity in very different ways.</p>*/}
            <div className='border-top'>
            <h4 className='font2 mt-5'>Expertice</h4>
                        <p className='mt-4'>UX/UI Design - Wireframing - Prototyping - Agile Methodologies - Usability Testing - HTML/CSS - JS - Web Design - Product Design - 3d modeling</p>
                      
            </div>
            <div>

            </div>
     
        </div>
      
      </div>
    </div>
    
    </>
  )
}

export default About
