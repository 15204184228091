import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import ImageLogoMonadic from './images/monadic-purple-white.svg';
import ImageLogoMonadicNormal from './images/purple-purple.svg';
import SitemapMonadic from './images/sitemap.png';
import TaskFlowMonadic from './images/taskFlow.png';
import UserFlowMonadic from './images/userFlow.png';
import FontGuidelines from './images/monadicGuidelines.svg';
import FirstApproach from './images/firstAproach.JPG';
import MonadicStart from './images/monadicStart.jpg';
import MonadicConf from './images/monadicConf.jpg';
import MonadicLanding from './images/monadicLanding.jpg';
import MonadicTemplateSelector from './images/monadicTemplateSelector.jpg';
import MonadicProduct from './images/monadicProduct.jpg';
import MonadicClients from './images/monadicClients.jpg';
import MonadicCategories from './images/monadicCategories.jpg';
import MonadicWebLanding from './images/landingWebMonadic.jpg';
import MonadicWebClients from './images/clientsWebMonadic.jpg';
import MonadicWebTemplates from './images/templatesWebMonadic.jpg';
import MonadicWebPlans from './images/plansWebMonadic.jpg';


import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
const {useRef, useState } = React;

function Monadic() {

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const [selectedImageIndexWeb, setSelectedImageIndexWeb] = useState(null);

  const openModalWeb = (index) => {
    setSelectedImageIndexWeb(index);
  };

  const images = [
    {
      src: MonadicStart,
      alt: 'monadicStart'
    },
    {
      src: MonadicConf,
      alt: 'monadicConf'
    },
    {
      src: MonadicLanding,
      alt: 'monadicLanding'
    },
    {
      src: MonadicTemplateSelector,
      alt: 'monadicTemplateSelector'
    },
    {
      src: MonadicProduct,
      alt: 'monadicProduct'
    },
    {
      src: MonadicCategories,
      alt: 'monadicCategories'
    },
    {
      src: MonadicClients,
      alt: 'monadicClients'
    }
  ];

  const imagesWeb = [
    {
      src: MonadicWebLanding,
      alt: 'monadicWebLanding'
    },
    {
      src: MonadicWebClients,
      alt: 'monadicWebClients'
    },
    {
      src: MonadicWebTemplates,
      alt: 'monadicWebTemplates'
    },
    {
      src: MonadicWebPlans,
      alt: 'monadicWebPlans'
    }
  ];

  const overViewContainer = useRef();
  const researchContainer = useRef();
  const architectureContainer = useRef();
  const conclusionContainer = useRef();
  const designContainer = useRef();
  const implementationContainer = useRef();

  const [showSitemap, setShowSitemap] = useState(false);
  const [showTaskflow, setShowTaskflow] = useState(false);
  const [showUserflow, setShowUserflow] = useState(false);


  const handleCloseSitemap = () => setShowSitemap(false);
  const handleCloseTaskflow = () => setShowTaskflow(false);
  const handleCloseUserflow = () => setShowUserflow(false);

  
  const handleShowSitemap = () => setShowSitemap(true);
  const handleShowTaskflow = () => setShowTaskflow(true);
  const handleShowUserflow = () => setShowUserflow(true);
 
    const overviewScroll = (e) => {
      e.stopPropagation();
      gsap.to(window, {
        scrollTo:{
          y: overViewContainer.current.offsetTop, autoKill: false, 
        }, 
        duration:0
       });
    };
    const architectureScroll = (e) => {
      e.stopPropagation();
      gsap.to(window, {
        scrollTo:{
          y: architectureContainer.current.offsetTop, autoKill: false, 
        }, 
        duration:0
       });
    };
    const researchScroll = (e) => {
      e.stopPropagation();
      gsap.to(window, {
        scrollTo:{
          y: researchContainer.current.offsetTop, autoKill: false, 
        }, 
        duration:0
       });
    };
    const conclusionScroll = (e) => {
      e.stopPropagation();
      gsap.to(window, {
        scrollTo:{
          y: conclusionContainer.current.offsetTop, autoKill: false, 
        }, 
        duration:0
       });
    };
    const designScroll = (e) => {
      e.stopPropagation();
      gsap.to(window, {
        scrollTo:{
          y: designContainer.current.offsetTop, autoKill: false, 
        }, 
        duration:0
       });
    };
    const implementationScroll = (e) => {
      e.stopPropagation();
      gsap.to(window, {
        scrollTo:{
          y: implementationContainer.current.offsetTop, autoKill: false, 
        }, 
        duration:0
       });
    };
  


 

  return (
    <>
    <div className='projectView'>
    <div className='container-fluid projectTitle mt-5'>
      <div className='row justify-content-center align-items-center h-100'>
        <div className='col-auto'>
        <img  src={ImageLogoMonadicNormal} alt='monadic' />
        </div>
      </div>
    </div>
    <div className='container my-5 d-none d-lg-block' >
      <div className='row justify-content-between'>
        <div className='col-auto'>
           <Button className='projectSections' variant="link" id="OverviewSectionBtn" onClick={overviewScroll}>Overview</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="researchSectionBtn" onClick={researchScroll}>Research</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="architectureSectionBtn" onClick={architectureScroll}>Information Architecture</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="designSectionBtn" onClick={designScroll}>Design</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="implementationSectionBtn" onClick={implementationScroll}>Implementation</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="conclusionSectionBtn" onClick={conclusionScroll}>Conclusion</Button>
        </div>
      </div>
    </div>
  
    <div className='container-fluid mt-5 py-5 handDrawnBorder' id="section1" ref={overViewContainer} >
      <div className='row '>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h2 className='mb-4 font2 mt-3'>Objective</h2>
              <p>Monadic, founded as a startup in 2015, set out to develop software that empowers users to create and manage their e-shops while centralizing their online and physical business operations in one unified platform.</p>
        <p>In my role within the design and frontend departments, my primary responsibility was to employ the most suitable methodologies for designing solutions that addressed user problems in a user-friendly and comprehensible manner. I was entrusted with branding design and the prototyping of interfaces for each new feature, focusing on making them visually appealing, easy to use, and efficient.</p>


          
          
           
              </div>
            </div>
          </div>
         
        </div>
       
      </div>
    </div>
    <div className='container-fluid mt-5 pt-5 px-0' id="section2" ref={researchContainer} >
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h2 className='mb-4 font2'>Research</h2>
        <p>Research, along with the use of structured methodologies and the development of a Minimum Viable Product (MVP), played a pivotal role in the success of our design process. It allowed us to gather rapid feedback from our users and pivot toward new solution ideas that we identified along the way</p>
  
        
              </div>
              <div className='col-12 mt-5'>
              <h4 className='mb-4 font2'>Persona</h4>
              <p>I conducted one-on-one interviews to create personas that provided insights into user frustrations, goals, time management, and technology proficiency. By synthesizing this data, we gained valuable insights and identified common patterns, which guided us in creating a comprehensive profile of our target users and informed our software development priorities.</p>
           
        
              </div>
          
            </div>
            
          </div>
         
        </div>
       
      </div>
      <div className='mt-5' style={{backgroundColor: "#00DDC6"}}>
          <div className='container persona text-center text-lg-start'>
            <div className='row justify-content-center m-0'>
              <div className='col-8 col-lg-3 p-0 personaBackground personaBackground1 my-4 my-lg-0'>
              </div>
              <div className='col-12 personaInfo col-md-6 col-lg-5 p-5' style={{backgroundColor: "#94FFCE"}}>
                <div className='d-none d-lg-block'>
                    <h6 className='font2 personaSection mt-2 mb-1'>name</h6>
                  <p className='mb-2 smallText'>Rodrigo Fernandez</p>
                  <h6 className='font2 personaSection mb-1'>age</h6>
                  <p className='mb-2 smallText'>32 years old</p>
                  <h6 className='font2 personaSection mb-1'>job title</h6>
                  <p className='mb-2 smallText'>Nutriologist</p>
                  <h6 className='font2 personaSection mb-1'>location</h6>
                  <p className='mb-2 smallText'>México City, México</p>
                </div>
                <div className='d-lg-none mb-4'>
                  <h4 className='font2'>Rodrigo Fernandez</h4>
                  <p className='mb-2 smallText'>32 years old / Nutriologist / México City, México</p>
                </div>
<h6 className='font2 personaSection'>about</h6>
<p className='smallText'>Rodrigo is the owner of a recently established supermarket specializing in organic products and supplements. Although he started his business not long ago, Rodrigo is highly motivated to achieve rapid growth. He is eager to expand his market reach and explore new avenues for sales. </p>
<div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>technology</h6>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Internet</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Social Media</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                  <div className='dot dotUnfilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Online Shopping</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Gadgets</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              </div>
              <div className='col-12 personaInfo col-md-6 col-lg-4 p-5' style={{backgroundColor: "#00FFE0"}}>
              <h6 className='font2 personaSection'>goals</h6>
<ul className='text-start'>
  <li>Accelerate the growth of his recently established supermarket business.</li>
  <li>Expand the customer base by venturing into online sales.</li>
  <li>Increase sales and revenues.</li>
  <li>Establish a strong online presence.</li>
  <li>Ensure a seamless transition from a physical store to an online platform.</li>
</ul>
              
              <div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>frustrations</h6>
<ul className='text-start'>
  <li>Challenges in setting up and managing an e-commerce platform.</li>
  <li>Worries about maintaining product quality and freshness during online sales.</li>
  <li>The need to quickly learn and adapt to new technologies and strategies.</li>
  <li>The potential for competition in the online market for organic products and supplements.</li>
 
</ul>
             
              </div>
      
            </div>
          </div>
      </div>
      <div style={{backgroundColor: "#E8591A"}}>
          <div className='container persona text-center text-lg-start'>
            <div className='row justify-content-center m-0'>
            <div className='col-8 order-lg-3 col-lg-3 p-0 personaBackground personaBackground2 my-4 my-lg-0'>
             

             </div>
              <div className='col-12 personaInfo col-md-6 col-lg-5 order-lg-1 p-5' style={{backgroundColor: "#FDDA64"}}>
              <div className='d-none d-lg-block'>
              <h6 className='font2 personaSection mt-2 mb-1'>name</h6>
              <p className='mb-2 smallText'>Mariel Fernández Celis</p>
              <h6 className='font2 personaSection mb-1'>age</h6>
              <p className='mb-2 smallText'>27 years old</p>
              <h6 className='font2 personaSection mb-1'>job title</h6>
              <p className='mb-2 smallText'>Marketer</p>
              <h6 className='font2 personaSection mb-1'>location</h6>
              <p className='mb-2 smallText'>Puebla, México</p>
                </div>
                <div className='d-lg-none mb-4'>
                  <h4 className='font2'>Mariel Fernández Celis</h4>
                  <p className='mb-2 smallText'>27 years old / Marketer / Puebla, México</p>
                </div>

           
<h6 className='font2 personaSection'>about</h6>
<p className='smallText'>Mariel is an ambitious entrepreneur who has successfully launched her own brand of collagen products. Her brand has gained recognition and popularity in pharmacies across Mexico due to its commitment to high-quality products and appealing branding.</p>
<div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>technology</h6>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Internet</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Social Media</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Online Shopping</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Gadgets</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>

              </div>
              <div className='col-12 personaInfo col-md-6 col-lg-4 order-lg-2 p-5' style={{backgroundColor: "#FF8C25"}}>
              <h6 className='font2 personaSection'>goals</h6>
<ul className='text-start'>
  <li>Continue to grow her collagen brand and expand into additional health products.</li>
  <li>Innovate and stay ahead in the competitive health and wellness market.</li>
  <li>Reach a broader audience with her high-quality products.</li>
  <li>Efficiently manage and scale her business operations.</li>
</ul>
              
              <div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>frustrations</h6>
<ul className='text-start'>
  <li>Balancing the challenges of scaling her business while ensuring consistent product quality.</li>
  <li>Managing the logistics and distribution of her expanding product line.</li>
  <li>Meeting high customer expectations.</li>
  <li>Adapting to the rapidly changing e-commerce landscape and technology trends.</li>
 
</ul>
              </div>
           
            </div>
          </div>
      </div>
    </div>


    <div className='container-fluid py-5' id="section3" ref={architectureContainer} style={{backgroundColor: "#f7f4ff"}}>
      <div className='row  mt-5'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h2 className='mb-4 font2'>Information Architecture</h2>
        <p>Creating an organized and intuitive software was a formidable challenge due to the numerous modules and functions involved. We harnessed insights from competitive research and conducted participant observations to validate initial wireframes.
        </p>
        <p>Subsequent tests allowed us to establish clear user and task flows and develop the initial UI design draft.</p>
        <h4 className='mb-4 mt-5 font2'>Sitemap</h4>
        <Button className='imageZoomButton' onClick={handleShowSitemap}>
        <img className='img-fluid'  src={SitemapMonadic} alt='sitemap' />
      </Button>
      
        <Modal className='modalImageZoom' show={showSitemap} onHide={handleCloseSitemap}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body> <img className='img-fluid'  src={SitemapMonadic} alt='sitemap' /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSitemap}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
        <h4 className='mb-4 mt-5 font2'>Task Flow</h4>
        <Button className='imageZoomButton' onClick={handleShowTaskflow}>
        <img className='img-fluid' src={TaskFlowMonadic} alt='taskFlow' />
      </Button>
       
        <Modal className='modalImageZoom' show={showTaskflow} onHide={handleCloseTaskflow}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body> <img className='img-fluid' src={TaskFlowMonadic} alt='taskFlow' /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTaskflow}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
        <h4 className='mb-4 mt-5 font2'>User Flow</h4>
        <Button className='imageZoomButton' onClick={handleShowUserflow}>
        <img className='img-fluid' src={UserFlowMonadic} alt='userFlow' />
      </Button>
       
        <Modal className='modalImageZoom' show={showUserflow} onHide={handleCloseUserflow}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body><img className='img-fluid' src={UserFlowMonadic} alt='userFlow' /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserflow}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
        
        
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid mt-5 py-5' id="section4" ref={designContainer} >
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h2 className='mb-4 font2'>Design</h2>
        <p>Designing a user-friendly and organized software was pivotal given the complexity of our product. Our design process heavily relied on insights from competitive research and participant observations during the testing of initial wireframes.</p>
        <p>After iterative testing, we refined user and task flows and crafted the first draft of the UI design.</p>
        <h4 className='mb-5 mt-5 font2'>Guidelines</h4>
        <h6 className='font2'>Logos</h6>
        <div className='row mt-4 m-0'>
          <div className='col-6 p-5 purpleBorder' >
          <img className='img-fluid' src={ImageLogoMonadicNormal} alt='monadicLogo' />
          </div>
          <div className='col-6 p-5 purpleBack'>
          <img className='img-fluid' src={ImageLogoMonadic} alt='monadicLogoWhite' />
          </div>
        </div>
        <h6 className='mt-5 font2'>Color palette</h6>
       
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4 '>
          <div className='col-lg p-0'>
           <div className='row m-0 h-100'>
              <div className='col-12 square backImage1'>

              </div>
          <div className='col-6 square' style={{backgroundColor: "#2E1A46"}}>
            <p className='text-end hexaColor'  style={{color: "#CB8BDA"}}>#2E1A46</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#8455EA"}}>
         <p className='text-end hexaColor'  style={{color: "#2E1A46"}}>#8455EA</p>
         </div>
         <div className='col-6 square backImage6'>
         
         </div>
         <div className='col-6 square' style={{backgroundColor: "#CB8BDA"}}>
         <p className='text-end hexaColor'  style={{color: "#2E1A46"}}>#CB8BDA</p>
         </div>
           </div>
          </div>
          <div className='col-lg p-0'>
           <div className='row m-0 h-100'>
             
              <div className='col-6 square' style={{backgroundColor: "#395DE9"}}>
              <p className='text-end hexaColor'  style={{color: "#85EFFF"}}>#395DE9</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#2086E5"}}>
         <p className='text-end hexaColor'  style={{color: "#85EFFF"}}>#2086E5</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#85EFFF"}}>
         <p className='text-end hexaColor'  style={{color: "#395DE9"}}>#85EFFF</p>
         </div>
         <div className='col-6 square backImage3'>
         
         </div>
        
         <div className='col-12 square backImage2'>

        </div>  
           </div>
          </div>
          <div className='col-lg p-0'>
          
           <div className='row m-0 h-100'>
           <div className='col-6 square backImage4'>
         
          </div>
              <div className='col-6 square' style={{backgroundColor: "#FDDA64"}}>
              <p className='text-end hexaColor'  style={{color: "#E8591A"}}>#FDDA64</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#E8591A"}}>
         <p className='text-end hexaColor'  style={{color: "#FDDA64"}}>#E8591A</p>
         </div>
        
         <div className='col-6 square ' style={{backgroundColor: "#FF8C25"}}>
         <p className='text-end hexaColor'  style={{color: "#FDDA64"}}>#FF8C25</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#777777"}}>
             <p className='text-end hexaColor'  style={{color: "#FBFBFC"}}>#777777</p>
        </div>
             <div className='col-6 square' style={{backgroundColor: "#FFFFFF"}}>
             <p className='text-end hexaColor'  style={{color: "#777777"}}>#FFFFFF</p>
        </div>
        <div className='col-6 square' style={{backgroundColor: "#FBFBFC"}}>
        <p className='text-end hexaColor'  style={{color: "#777777"}}>#FBFBFC</p>
        </div>
       
        <div className='col-6 square ' style={{backgroundColor: "#F8F6FF"}}>
        <p className='text-end hexaColor'  style={{color: "#777777"}}>#F8F6FF</p>
        </div>
           </div>
          </div>
          <div className='col-lg p-0'>
           <div className='row m-0 h-100'>
             
              <div className='col-6 square' style={{backgroundColor: "#008174"}}>
              <p className='text-end hexaColor'  style={{color: "#00FFE0"}}>#008174</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#00DDC6"}}>
         <p className='text-end hexaColor'  style={{color: "#008174"}}>#00DDC6</p>
         </div>
         <div className='col-6 square backImage5'>
         
         </div>
         <div className='col-6 square ' style={{backgroundColor: "#00FFE0"}}>
         <p className='text-end hexaColor'  style={{color: "#008174"}}>#00FFE0</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#94FFCE"}}>
         <p className='text-end hexaColor'  style={{color: "#005954"}}>#94FFCE</p>
         </div>
         <div className='col-6 square backImage7'>
         
         </div>
         <div className='col-6 square' style={{backgroundColor: "#005954"}}>
         <p className='text-end hexaColor'  style={{color: "#94FFCE"}}>#005954</p>
         </div>
         <div className='col-6 square' style={{backgroundColor: "#00C6B5"}}>
         <p className='text-end hexaColor'  style={{color: "#005954"}}>#00C6B5</p>
         </div>
           </div>
          </div>
        </div>
        <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
        <h6 className='mt-5 font2'>Fonts</h6>
        <img className='img-fluid mt-4' src={FontGuidelines} alt='fonts' />
        <h6 className='mt-5 font2'>First design approach</h6>
        <img className='img-fluid mt-4' src={FirstApproach} alt='firstApproach' />
        
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid mt-5 py-5' id="section5" ref={implementationContainer} style={{backgroundColor: "#8455EA"}}>
      <div className='row mt-5'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 text-white'>
              <h2 className='mb-4 font2'>Implementation</h2>
              <p className='text-white'>We conducted usability testing using high-fidelity prototypes, gaining valuable insights into how we could enhance visual design, functionality, and navigation for the final product. Our software was designed with flexibility in mind, allowing us to continually add new features and solutions to address evolving user needs.</p>
              
              <h4 className=" mt-5 font2">Software</h4>
              <div className='row m-0 p-2 p-lg-4 mt-4 implementationContainer' id="gallery" >
              {images.map((image, index) => (
                  <div className='col-lg-6 my-2 px-2 my-lg-4 px-lg-4'>
                  
                  <img className='img-fluid'
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => openModal(index)}
                    />
                  </div>
                  
                  ))}
             
              </div>
          
              </div>
            </div>
          </div>
         
        </div>
       
      </div>
      <Modal className='modalImageZoom' show={selectedImageIndex !== null} onHide={() => setSelectedImageIndex(null)}>
      <Modal.Header closeButton>
          
          </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={selectedImageIndex} onSelect={(index) => setSelectedImageIndex(index)}>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img className='img-fluid' src={image.src} alt={image.alt} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
   
      </Modal>
     
    <div className='row'>
        <div className='col-12'>
          <div className='container'>
          <div className='row'>
              <div className='col-12 text-white'>
          
              <h4 className=" mt-5 font2">Website</h4>
          
          
              </div>
            </div>
            <div className='row m-0 p-2 p-lg-4 mt-4 implementationContainer' id="galleryWeb" style={{backgroundColor: "#f1eaff"}}>
              {imagesWeb.map((image, index) => (
                  <div className='col-6 col-lg-3 my-2 px-2 my-lg-4 px-lg-4'>
                  
                  <img className='img-fluid'
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => openModalWeb(index)}
                    />
                  </div>
                  
                  ))}
             
              </div>
          </div>
         
        </div>
       
      </div>
      <Modal className='modalImageZoom' show={selectedImageIndexWeb !== null} onHide={() => setSelectedImageIndexWeb(null)}>
      <Modal.Header closeButton>
          
          </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={selectedImageIndexWeb} onSelect={(index) => setSelectedImageIndexWeb(index)}>
            {imagesWeb.map((image, index) => (
              <Carousel.Item className='carouselWeb' key={index}>
                <img className='img-fluid' src={image.src} alt={image.alt} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
   
      </Modal>
    </div>
    <div className='container-fluid mt-5 pt-5' id="section6" ref={conclusionContainer} >
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h2 className='mb-4 font2'>Conclusion</h2>
        <p>Monadic's journey began as an idea, but our unwavering commitment to clear objectives and innovation set us apart in a competitive market. The development of a comprehensive software solution with numerous functionalities required rigorous testing using high-quality prototypes, enabling us to refine and optimize our ideas effectively.


          </p>
          <p>Today, our software continues to evolve and adapt to changing user needs while maintaining a strong foundation that facilitates seamless integration of necessary solutions.</p>   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  )
}

export default Monadic