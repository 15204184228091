import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home.js';
import NavBar from './NavBar.js';
import Footer from './Footer.js';
import Monadic from './Monadic.js';
import T1Paginas from './T1Paginas.js';
import About from './About.js';
import Contact from './ContactMe.js';
import {Routes, Route} from 'react-router-dom';
import './index.css';

function App() {
  return (
    <>
    <NavBar />
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/monadic/" element={<Monadic />} />
      <Route exact path="/t1paginas/" element={<T1Paginas />} />
      <Route exact path="/about/" element={<About />} />
      <Route exact path="/contact/" element={<Contact />} />
    </Routes>
    <Footer />
    </>
  )
}

export default App;
