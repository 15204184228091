import React, { useState } from 'react';

const ColumnComponent = ({workNumber, title, description, tool, projectImage, projectImageAlt,textMode}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    
    const toggleColumn = () => {
      setIsOpen(!isOpen);
    };
  
    return (
            <div className={`col-md-${isOpen ? '12' : '6'} col-lg-${isOpen ? '12' : '4'} p-0 backWrapper`} onClick={toggleColumn}>
              <div className='projectImageContainer'>
              <img className='img-fluid projectImage mh-100' src={`${projectImage}`} alt={`${projectImageAlt}`}></img>
              </div>
           
           {!isOpen &&  <div className={`smallWave wave${workNumber}`}>
            </div>}
           <div className={`${isOpen ? 'innerTextOpened' : 'innerText'} innerText8 innerText${textMode} p-4`}>
                  <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                  <h3 className='m-0'>{title}</h3> 
                 
                  </div>
                  <div className='d-flex justify-content-center justify-content-lg-start'>
                
                  <p>{description}</p>
                  </div>
                  <div>
                  <span class="badge text-bg-primary me-1">{tool}</span>
                  </div>
                </div>
                 <div className={`square ${isOpen ? 'squareOpened' : ''} workContainer work${workNumber}`}>
                </div>
         
            </div>
    );
  };
  
  export default ColumnComponent;