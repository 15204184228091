import React from 'react'


function About() {
  return (
    <>
    <div className='container projectView text-center text-lg-start'>
      <div className='row align-items-center justify-content-center justify-content-lg-start mt-5 pt-5'>
          <div className='col-auto'>
          <div className='MarkerLine topLine'>

          </div>
          <div className='MarkerLine my-4'>

          </div>
          <div className='MarkerLine bottomLine'>

          </div>
          </div>
          <div className='col-auto justify-content-center'>
          <h1 className='font1 m-0 text-center mx-4'>Let's Connect</h1>
          </div>
          <div className='col-auto'>
          <div className='MarkerLine topLineInverse'>

          </div>
          <div className='MarkerLine my-4'>

          </div>
          <div className='MarkerLine bottomLineInverse'>

          </div>
          </div>
      </div>
      <div className='row  mt-5 pt-5'>
      
         {/* <div className='col-12 mb-5'>
           <h1 className='font1'>Let's Connect</h1>
      </div> */}
        <div className='col-md-6 col-lg-4'>
        <h2 className='font2'>Contact</h2>
        <p className='mt-4 mb-0'>erick.gallardo.t@gmail.com</p>
        <p className='mt-4 mb-0'>+521(222)554-80-37</p>
        </div>
        <div className='col-md-6 col-lg-4 mt-5 mt-lg-0'>
        <h2 className='font2'>On the web</h2>
       <div className='mt-4'>
       <a className='modalLink mt-4 font2 fw-bold' href="https://www.linkedin.com/in/erickgallardo/" target="_blank" rel="noreferrer">LinkedIn</a>
       </div>
       <div className='mt-4'>
       <a className='modalLink mt-4 font2 fw-bold' href="https://www.behance.net/ErickGallard0" target="_blank" rel="noreferrer">Behance</a>
       </div>
        
       
        
 
        </div>
      </div>
    </div>
    
    </>
  )
}

export default About