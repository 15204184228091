import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import ImageLogoT1 from './images/t1paginasLogotipo.svg';
import SitemapT1 from './images/sitemapT1.png';
import TaskFlowT1 from './images/taskFlowT1.png';
import UserFlowT1 from './images/userFlowT1.png';
import t1Guidelines from './images/LibreriaT1.png';
import T1Login from './images/T1Login.jpg';
import T1Authenticator from './images/T1Authenticator.jpg';
import T1Adress from './images/T1Adress.jpg';
import T1StoreName from './images/T1StoreName.jpg';
import T1Plan from './images/T1Plan.jpg';
import T1Payment from './images/T1Payment.jpg';
import T1Templates from './images/T1Templates.jpg';
import T1Products from './images/T1Products.jpg';
import T1Orders from './images/T1Orders.jpg';
import T1Marketing from './images/T1Marketing.jpg';
import T1Subscription from './images/T1Subscription.jpg';
import T1Homepage from './images/T1Homepage.png';
import T1Information from './images/T1Information.png';
import T1Blog from './images/T1Blog.png';
import T1Help from './images/T1Help.png';
import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);
const {useRef, useState } = React;

function T1Paginas() {

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const [selectedImageIndexWeb, setSelectedImageIndexWeb] = useState(null);

  const openModalWeb = (index) => {
    setSelectedImageIndexWeb(index);
  };

  const images = [
    {
      src: T1Login,
      alt: 't1Login'
    },
    {
      src: T1Authenticator,
      alt: 't1Authenticator'
    },
    {
      src: T1StoreName,
      alt: 't1StoreName'
    },
    {
      src: T1Adress,
      alt: 't1Adress'
    },
    {
      src: T1Plan,
      alt: 't1Plan'
    },
    {
      src: T1Payment,
      alt: 't1Payment'
    },
    {
      src: T1Templates,
      alt: 't1Templates'
    },
    {
      src: T1Products,
      alt: 't1Products'
    },
    {
      src: T1Orders,
      alt: 't1Orders'
    },
    {
      src: T1Marketing,
      alt: 't1Marketing'
    },
    {
      src: T1Subscription,
      alt: 't1Subscription'
    }
  ];

  const imagesWeb = [
    {
      src: T1Homepage,
      alt: 't1Homepage'
    },
    {
      src: T1Information,
      alt: 't1Information'
    },
    {
      src: T1Blog,
      alt: 't1Blog'
    },
    {
      src: T1Help,
      alt: 't1Help'
    }
  ];

  const overViewContainer = useRef();
  const researchContainer = useRef();
  const architectureContainer = useRef();
  const conclusionContainer = useRef();
  const designContainer = useRef();
  const implementationContainer = useRef();

  const [showSitemap, setShowSitemap] = useState(false);
  const [showTaskflow, setShowTaskflow] = useState(false);
  const [showUserflow, setShowUserflow] = useState(false);


  const handleCloseSitemap = () => setShowSitemap(false);
  const handleCloseTaskflow = () => setShowTaskflow(false);
  const handleCloseUserflow = () => setShowUserflow(false);

  
  const handleShowSitemap = () => setShowSitemap(true);
  const handleShowTaskflow = () => setShowTaskflow(true);
  const handleShowUserflow = () => setShowUserflow(true);

  const overviewScroll = (e) => {
    e.stopPropagation();
    gsap.to(window, {
      scrollTo:{
        y: overViewContainer.current.offsetTop, autoKill: false, 
      }, 
      duration:0
     });
  };
  const architectureScroll = (e) => {
    e.stopPropagation();
    gsap.to(window, {
      scrollTo:{
        y: architectureContainer.current.offsetTop, autoKill: false, 
      }, 
      duration:0
     });
  };
  const researchScroll = (e) => {
    e.stopPropagation();
    gsap.to(window, {
      scrollTo:{
        y: researchContainer.current.offsetTop, autoKill: false, 
      }, 
      duration:0
     });
  };
  const conclusionScroll = (e) => {
    e.stopPropagation();
    gsap.to(window, {
      scrollTo:{
        y: conclusionContainer.current.offsetTop, autoKill: false, 
      }, 
      duration:0
     });
  };
  const designScroll = (e) => {
    e.stopPropagation();
    gsap.to(window, {
      scrollTo:{
        y: designContainer.current.offsetTop, autoKill: false, 
      }, 
      duration:0
     });
  };
  const implementationScroll = (e) => {
    e.stopPropagation();
    gsap.to(window, {
      scrollTo:{
        y: implementationContainer.current.offsetTop, autoKill: false, 
      }, 
      duration:0
     });
  };

  return (
    <>
    <div className='projectView'>
    <div className='container-fluid projectTitle mt-5'>
      <div className='row justify-content-center align-items-center h-100'>
        <div className='col-auto'>
        <img  src={ImageLogoT1} alt='t1Logo' />
        </div>
      </div>
    </div>
    <div className='container my-5 d-none d-lg-block' >
      <div className='row justify-content-between'>
        <div className='col-auto'>
           <Button className='projectSections' variant="link" id="OverviewSectionBtn" onClick={overviewScroll}>Overview</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="researchSectionBtn" onClick={researchScroll}>Research</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="architectureSectionBtn" onClick={architectureScroll}>Information Architecture</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="designSectionBtn" onClick={designScroll}>Design</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="implementationSectionBtn" onClick={implementationScroll}>Implementation</Button>
        </div>
        <div className='col-auto'>
        <Button className='projectSections' variant="link" id="conclusionSectionBtn" onClick={conclusionScroll}>Conclusion</Button>
        </div>
      </div>
    </div>
    <div className='container-fluid mt-5 py-5 handDrawnBorderT1' id="section1" ref={overViewContainer}>
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 '>
              <h1 className='mb-4 font2 mt-3'>Objective</h1>
              <p>In 2019, Grupo Carso, S.A.B. de C.V. offered us the opportunity to integrate Monadic with their platform, T1, for the creation of online services. Their interest stemmed from the comprehensive toolset our software offered. Over the course of nearly a year, we diligently adapted our software to align with T1's existing structure, models, APIs, and design.</p>
        <p>During this project, I collaborated closely with their team to formulate the final T1 guidelines. Additionally, I spearheaded the redesign of the Monadic software and played a pivotal role in designing and developing the T1 Páginas website, along with the user workflow connecting it to the software initiation process.</p>           
              </div>
            </div>
          </div>
         
        </div>
       
      </div>
    </div>
    <div className='container-fluid mt-5 pt-5 px-0' id="section2" ref={researchContainer} >
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h1 className='mb-4 font2'>Research</h1>
        <p>As part of the Monadic team, I conducted an in-depth analysis of the initial state of T1's software. With the assistance of their team, we refined the design and workflow of the final software by combining the best elements from both projects. Both teams had previously conducted user research and analyzed the tools used in their respective software, streamlining the process of testing the final product.</p>

        
              </div>
              <div className='col-12 mt-5'>
              <h2 className='mb-4 font2'>Persona</h2>
              <p>To gain a deeper understanding of potential T1 Páginas users, I employed persona development techniques. The high level of interest in our new product led to significant communication with potential users, providing valuable insights that we leveraged to enhance the tools we were developing.</p>
            
        
              </div>
          
            </div>
            
          </div>
         
        </div>
       
      </div>
      


      <div className='mt-5' style={{backgroundColor: "#DB3B2B"}}>
          <div className='container persona text-center text-lg-start'>
            <div className='row justify-content-center m-0'>
              <div className='col-8 col-lg-3 p-0 personaBackground personaBackground3 my-4 my-lg-0'>
              </div>
              <div className='col-12 personaInfo col-md-6 col-lg-5 p-5' style={{backgroundColor: "#ffa39b"}}>
              <div className='d-none d-lg-block'>
              <h6 className='font2 personaSection mt-2 mb-1'>name</h6>
              <p className='mb-2 smallText'>Bárbara de Regil</p>
              <h6 className='font2 personaSection mb-1'>age</h6>
              <p className='mb-2 smallText'>36 years old</p>
              <h6 className='font2 personaSection mb-1'>job title</h6>
              <p className='mb-2 smallText'> Actress and Loving It CEO</p>
              <h6 className='font2 personaSection mb-1'>location</h6>
              <p className='mb-2 smallText'>México City, México</p>
                </div>
                <div className='d-lg-none mb-4'>
                  <h4 className='font2'>Bárbara de Regil</h4>
                  <p className='mb-2 smallText'>36 years old / Actress and Loving It CEO / México City, México</p>
                </div>
          
<h6 className='font2 personaSection'>about</h6>
<p className='smallText'>To gain a deeper understanding of potential T1 Páginas users, I employed persona development techniques. The high level of interest in our new product led to significant communication with potential users, providing valuable insights that we leveraged to enhance the tools we were developing.</p>
<div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>technology</h6>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Internet</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Social Media</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFille'></div>
                  <div className='dot dotFille'></div>
                  <div className='dot dotFille'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Online Shopping</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFille'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Gadgets</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              </div>
              <div className='col-12 personaInfo col-md-6 col-lg-4 p-5' style={{backgroundColor: "#fbd8d5"}}>
              <h6 className='font2 personaSection'>goals</h6>
<ul className='text-start'>
  <li>Offer high-quality protein products through her website.</li>
  <li>Share valuable nutritional information and recipes with her audience.</li>
  <li>Motivate and inspire others to lead healthier lifestyles.</li>
  <li>Open an online subscription to a fitness workout plan.</li>
</ul>
              
              <div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>frustrations</h6>
<ul className='text-start'>
  <li>Managing the technical aspects of her website.</li>
  <li>Managing her concern about the launch of her online fitness subscription plan, particularly the worries about handling potentially high website traffic during the launch phase.</li>
  <li>Balancing the demands of her acting career with her health and wellness brand.</li>
 
</ul>
             
              </div>
      
            </div>
          </div>
      </div>
      <div style={{backgroundColor: "#41BAC6"}}>
          <div className='container persona text-center text-lg-start'>
            <div className='row justify-content-center m-0'>
            <div className='col-8 order-lg-3 col-lg-3 p-0 personaBackground personaBackground4 my-4 my-lg-0'>
             

             </div>
              <div className='col-12 personaInfo col-md-6 col-lg-5 order-lg-1 p-5' style={{backgroundColor: "#66dbe7"}}>
              <div className='d-none d-lg-block'>
              <h6 className='font2 personaSection mt-2 mb-1'>name</h6>
              <p className='mb-2 smallText'>Claudio Simón</p>
              <h6 className='font2 personaSection mb-1'>age</h6>
              <p className='mb-2 smallText'>45 years old</p>
              <h6 className='font2 personaSection mb-1'>job title</h6>
              <p className='mb-2 smallText'>Pelikan group CMO</p>
              <h6 className='font2 personaSection mb-1'>location</h6>
              <p className='mb-2 smallText'>Puebla, México</p>
                </div>
                <div className='d-lg-none mb-4'>
                  <h4 className='font2'>Claudio Simón</h4>
                  <p className='mb-2 smallText'>45 years old / Pelikan group CMO / Puebla, México</p>
                </div>

            
<h6 className='font2 personaSection'>about</h6>
<p className='smallText'> Claudio is an experienced professional who has been a valuable member of the Pelikan International team. He recently assumed the role of Chief Marketing Officer (CMO) at Pelikan México with a specific focus on driving sales growth in Latin America.</p>
<div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>technology</h6>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Internet</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Social Media</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                  <div className='dot dotUnfilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Online Shopping</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-2'>
                <p className='mb-2 mb-lg-0 smallText techText'>Gadgets</p>
                <div className='d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotFilled'></div>
                  <div className='dot dotUnfilled'></div>
                </div>
              </div>

              </div>
              <div className='col-12 personaInfo col-md-6 col-lg-4 order-lg-2 p-5' style={{backgroundColor: "#a2f6ff"}}>
              <h6 className='font2 personaSection'>goals</h6>
<ul className='text-start'>
  <li>Increase sales in the Latin American market for Pelikan México.</li>
  <li>Develop a robust online presence for Pelikan México.</li>
  <li>Implement effective social media marketing strategies.</li>
  <li>Achieve sales growth despite the challenges posed by the COVID-19 pandemic.</li>
</ul>
              
              <div className='border-bottom border-dark mb-3'></div>
<h6 className='font2 personaSection'>frustrations</h6>
<ul className='text-start'>
  <li>Achieve sales growth despite the challenges posed by the COVID-19 pandemic.</li>
  <li>The need to quickly pivot marketing strategies to align with emerging trends.</li>
  <li>The time to complete all the plans was short.</li>
  <li>Navigating the uncertainties and disruptionsin the Latin American market.</li>
 
</ul>
              </div>
           
            </div>
          </div>
      </div>
    </div>
    <div className='container-fluid py-5' id="section3" ref={architectureContainer} style={{backgroundColor: "#fdfdfd"}}>
      <div className='row  mt-5'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h1 className='mb-4 font2'>Information Architecture</h1>
        <p>Creating an organized and user-friendly software was crucial, given the multitude of modules and functions involved. Our approach included competitive research and participant observation to validate initial wireframes. Our primary goal was to maintain organized modules, optimize user flow, and seamlessly integrate tools from both software solutions, creating a cohesive user experience. This task was challenging due to the differing structures and logics of the two software systems, necessitating extensive research and adaptation.</p>

        <h4 className='mb-4 mt-5 font2'>Sitemap</h4>
        <Button className='imageZoomButton' onClick={handleShowSitemap}>
        <img className='img-fluid'  src={SitemapT1} alt='sitemap' />
      </Button>
      
        <Modal className='modalImageZoom' show={showSitemap} onHide={handleCloseSitemap}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body> <img className='img-fluid'  src={SitemapT1} alt='sitemap' /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSitemap}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
        <h4 className='mb-4 mt-5 font2'>Task Flow</h4>
        <Button className='imageZoomButton' onClick={handleShowTaskflow}>
        <img className='img-fluid' src={TaskFlowT1} alt='taskFlow' />
      </Button>
       
        <Modal className='modalImageZoom' show={showTaskflow} onHide={handleCloseTaskflow}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body> <img className='img-fluid' src={TaskFlowT1} alt='taskFlow' /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTaskflow}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
        <h4 className='mb-4 mt-5 font2'>User Flow</h4>
        <Button className='imageZoomButton' onClick={handleShowUserflow}>
        <img className='img-fluid' src={UserFlowT1} alt='userFlow' />
      </Button>
       
        <Modal className='modalImageZoom' show={showUserflow} onHide={handleCloseUserflow}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body><img className='img-fluid' src={UserFlowT1} alt='userFlow' /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserflow}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
        
        
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid mt-5 py-5' id="section4" ref={designContainer} >
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h1 className='mb-4 font2'>Design</h1>
        <p>My primary responsibility was to enhance the UX/UI design and establish design guidelines to streamline collaboration between the two teams. Although I faced some limitations, such as incorporating existing design patterns from T1, their team was highly receptive to necessary improvements. The results exceeded expectations.
        </p>
        
        <h4 className='mb-4 mt-5 font2'>Guidelines</h4>
        <img className='img-fluid' src={t1Guidelines} alt='t1Guidelines' />
      

       
              </div>
            </div>
          </div>
        </div>
      </div>
      
     
    </div>
    <div className='container-fluid mt-5 py-5' id="section5" ref={implementationContainer} style={{backgroundColor: "#f6f7f7"}}>
      <div className='row mt-5'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h1 className='mb-4 font2'>Implementation</h1>
              <p>Through usability testing with high-fidelity prototypes, we identified opportunities for improvement in the final product. After several iterations to refine visual design, functionality, and navigation, the final product was ready for development. Extensive user testing involving participants from both software systems allowed us to iterate until we achieved a highly usable software solution. T1 Páginas emerged as a comprehensive platform, encompassing all the tools required for starting an online business. Its adaptable architecture ensures ease of updates, improvements, and module creation.</p>
              <h4 class="mt-5 font2">Software</h4>
              <div className='row m-0 p-2 p-lg-4 mt-4 implementationContainer' id="gallery" style={{backgroundColor: "#ffffff"}}>
              {images.map((image, index) => (
                  <div className='col-lg-6 my-2 px-2 my-lg-4 px-lg-4'>
                  
                  <img className='img-fluid'
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => openModal(index)}
                    />
                  </div>
                  
                  ))}
             
              </div>
          
              </div>
            </div>
          </div>
         
        </div>
       
      </div>
      <Modal className='modalImageZoom' show={selectedImageIndex !== null} onHide={() => setSelectedImageIndex(null)}>
      <Modal.Header closeButton>
          
          </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={selectedImageIndex} onSelect={(index) => setSelectedImageIndex(index)}>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img className='img-fluid' src={image.src} alt={image.alt} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
   
      </Modal>
     
    <div className='row'>
        <div className='col-12'>
          <div className='container'>
          <div className='row'>
              <div className='col-12'>
          
              <h4 class="mt-5 font2">Website</h4>
          
          
              </div>
            </div>
            <div className='row m-0 p-2 p-lg-4 mt-4 implementationContainer' id="galleryWeb" style={{backgroundColor: "#ffffff"}}>
              {imagesWeb.map((image, index) => (
                  <div className='col-6 col-lg-3 my-2 px-2 my-lg-4 px-lg-4'>
                  
                  <img className='img-fluid'
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => openModalWeb(index)}
                    />
                  </div>
                  
                  ))}
             
              </div>
          </div>
         
        </div>
       
      </div>
      <Modal className='modalImageZoom' show={selectedImageIndexWeb !== null} onHide={() => setSelectedImageIndexWeb(null)}>
      <Modal.Header closeButton>
          
          </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={selectedImageIndexWeb} onSelect={(index) => setSelectedImageIndexWeb(index)}>
            {imagesWeb.map((image, index) => (
              <Carousel.Item className='carouselWeb' key={index}>
                <img className='img-fluid' src={image.src} alt={image.alt} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
   
      </Modal>
    </div>
    <div className='container-fluid mt-5 pt-5' id="section6" ref={conclusionContainer} >
      <div className='row'>
        <div className='col-12'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
              <h1 className='mb-4 font2'>Conclusion</h1>
        <p>Participating in the fusion of two software systems represented a unique experience for me, as I was accustomed to building from scratch. Despite the inherent challenges arising from their distinct characteristics, both software solutions provided comprehensive and helpful documentation, which greatly facilitated collaboration between our teams.</p>
          <p>T1 Páginas stands as a testament to our successful collaboration, offering a robust solution for businesses seeking to establish an online presence. Its adaptable framework ensures it remains primed for future enhancements and updates, cementing its position as a versatile tool for online business endeavors.</p>   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  )
}

export default T1Paginas