import React from 'react';
import {Link} from 'react-router-dom';
import ColumnComponent from './ColumnComponent';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExternalLink from './images/external-link.svg';
import ExternalLinkDark from './images/external-link-dark.svg';
import logoImage from './images/erickLogo.svg';
import SpacePrimate from './images/spacePrimate.gif';
import Degen from './images/degen.jpg';
import AvatarDape from './images/avatar-dape.jpg';
import TheRing from './images/the-ring.jpg';
import Frens from './images/frens.jpg';
import Car from './images/car.jpg';
import Cd from './images/cd.jpg';
import Hape from './images/hape.jpg';



/*gsap anim*/
import { gsap} from "gsap";

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const { useLayoutEffect } = React;
/*gsap anim*/

function Home() {

  useLayoutEffect(() => {

 }, []);



  return (
    <>
    <div className='paperBackgorund projectView'>
      <div className='container text-center text-lg-start'>
        <div className='d-lg-none row justify-content-center mt-5'>
            <div className='col-auto'>
              <img className='img-fluid egLogo' src={logoImage} alt='taskFlow' />
            </div>
        </div>
        <div className='row'>
        <div className='col-12 mt-5'>
          <h1 className='font1 mb-5 pb-lg-5'>My Work</h1>
        </div>
        <div className='col-12 mb-lg-5 mb-4'>
          <h3>Case Studies</h3>
        </div>
        </div>
          <div className='row m-0'>
              <div className='col-lg-6 p-0 backWrapper'>
              <Link to="/monadic/">
                <div className='wave wave1'>

                </div>
                <div className=' innerText innerText1 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Monadic</h3> 
                   
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                  <p>UX/UI Design / Brand / Visual Design</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">Figma</span>
                    <span class="badge text-bg-primary mx-1">Illustrator</span>
                    <span class="badge text-bg-primary mx-1">Photoshop</span>
                    <span class="badge text-bg-primary mx-1">Design Thinking</span>
                    </div>
                  </div>
                  <div className='square work1 workContainer'>

                  </div>
                  </Link>
              </div>
              <div className='col-lg-6 p-0 backWrapper'>
              <Link to="/t1paginas/">
              <div className='wave wave2'>
              </div>
              <div className=' innerText innerText2 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>T1 Paginas</h3> 
                  
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                  <p>UX/UI Design / Brand / Visual Design</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">Figma</span>
                    <span class="badge text-bg-primary mx-1">Illustrator</span>
                    <span class="badge text-bg-primary mx-1">Photoshop</span>
                    <span class="badge text-bg-primary mx-1">Design Thinking</span>
                    </div>
                  </div>
                  <div className='square work2 workContainer'>

                  </div>
                  </Link>
              </div>
          </div>
          <div className='row mt-5 pt-lg-5'>
        <div className='col-12 mb-lg-5 mb-4'>
          <h3>Projects</h3>
        </div>
        <div className='col-12'>
        <Tabs
      defaultActiveKey="allProjects"
      id="uncontrolled-tab-example"
      className="mb-3"
       >
      <Tab eventKey="allProjects" title="All">
      <div className='row m-0'>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://pelikanshop.mx/" target="_blank" rel="noreferrer">
                  <div className='smallWave wave3'>
                  </div>
                  <div className=' innerText innerText3 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Pelikan Mexico</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                  <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work3 workContainer'>

                  </div>
              </a>
          
            
           
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://cebeth.com/" target="_blank" rel="noreferrer">
              <div className='smallWave wave4'>
              </div>
              <div className=' innerText innerText4 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Cebeth</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work4 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://sesencompany.com/" target="_blank" rel="noreferrer">
              <div className='smallWave wave5'>
              </div>
              <div className=' innerText innerText5 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Sesen</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work5 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://beable.mx/" target="_blank" rel="noreferrer">
              <div className='smallWave wave6'>
              </div>
              <div className=' innerText innerText6 innerTextLight p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Able</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLinkDark} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                    <p>Development</p>
                    <p>Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work6 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://lovingit.mx/" target="_blank" rel="noreferrer">
              <div className='smallWave wave7'>
              </div>
              <div className=' innerText innerText7 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Loving It</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work7 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://dicass.com.mx/" target="_blank" rel="noreferrer">
              <div className='smallWave wave8'>
              </div>
              <div className=' innerText innerText8 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Dicass</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work8 workContainer'>

                  </div>
                  </a>
              </div>
          <ColumnComponent title="Art Intervention" description="Animation / Ataul. Primates" tool="Photoshop" projectImage={SpacePrimate} projectImageAlt="Space primate" workNumber="9" textMode="Dark" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={Degen} projectImageAlt="Degen" workNumber="10" textMode="Dark" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={AvatarDape} projectImageAlt="Avatar dape"  workNumber="11" textMode="Dark" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={TheRing} projectImageAlt="The ring otherside"  workNumber="12" textMode="Light" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={Frens} projectImageAlt="medieval frens"  workNumber="13" textMode="Dark" />
          <ColumnComponent title="" description="Illustration" tool="Colored Pencils" projectImage={Cd} projectImageAlt="cd colored"  workNumber="15" textMode="Light" />
          <ColumnComponent title="" description="Illustration" tool="Acrylic Paint" projectImage={Car} projectImageAlt="cart painted"  workNumber="14" textMode="Dark" />
          <ColumnComponent title="" description="Sculpture" tool="Modelling Clay" projectImage={Hape} projectImageAlt="hape sculpture"  workNumber="16" textMode="Light" />
       
          </div>
      </Tab>
     <Tab eventKey="web" title="Web">
     <div className='row m-0'>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://pelikanshop.mx/" target="_blank" rel="noreferrer">
                  <div className='smallWave wave3'>
                  </div>
                  <div className=' innerText innerText3 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Pelikan Mexico</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                  <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work3 workContainer'>

                  </div>
              </a>
          
            
           
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://cebeth.com/" target="_blank" rel="noreferrer">
              <div className='smallWave wave4'>
              </div>
              <div className=' innerText innerText4 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Cebeth</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work4 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://sesencompany.com/" target="_blank" rel="noreferrer">
              <div className='smallWave wave5'>
              </div>
              <div className=' innerText innerText5 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Sesen</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work5 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://beable.mx/" target="_blank" rel="noreferrer">
              <div className='smallWave wave6'>
              </div>
              <div className=' innerText innerText6 innerTextLight p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Able</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLinkDark} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                    <p>Development</p>
                    <p>Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work6 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://lovingit.mx/" target="_blank" rel="noreferrer">
              <div className='smallWave wave7'>
              </div>
              <div className=' innerText innerText7 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Loving It</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work7 workContainer'>

                  </div>
                  </a>
              </div>
              <div className='col-md-6 col-lg-4 p-0 backWrapper'>
              <a href="https://dicass.com.mx/" target="_blank" rel="noreferrer">
              <div className='smallWave wave8'>
              </div>
              <div className=' innerText innerText8 innerTextDark p-4'>
                    <div className='d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start mb-2'>
                    <h3 className='m-0'>Dicass</h3> 
                    <img className='img-fluid externalLinkIcon ms-3' src={ExternalLink} alt='externalLink' />
                    </div>
                    <div className='d-flex justify-content-center justify-content-lg-start'>
                  
                    <p>Web Design / Development</p>
                    </div>
                    <div>
                    <span class="badge text-bg-primary me-1">HTML</span>
                    <span class="badge text-bg-primary mx-1">CSS</span>
                    <span class="badge text-bg-primary mx-1">JavaScript</span>
                    <span class="badge text-bg-primary mx-1">Angular</span>
                    <span class="badge text-bg-primary mx-1">T1</span>
                    </div>
                  </div>
                  <div className='square work8 workContainer'>

                  </div>
                  </a>
              </div>
    
       
          </div>
      </Tab>
      <Tab eventKey="digital" title="Digital">
      <div className='row m-0'>
          <ColumnComponent title="Art Intervention" description="Animation / Ataul. Primates" tool="Photoshop" projectImage={SpacePrimate} projectImageAlt="Space primate" workNumber="9" textMode="Dark" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={Degen} projectImageAlt="Degen" workNumber="10" textMode="Dark" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={AvatarDape} projectImageAlt="Avatar dape"  workNumber="11" textMode="Dark" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={TheRing} projectImageAlt="The ring otherside"  workNumber="12" textMode="Light" />
          <ColumnComponent title="" description="Digital Illustration" tool="Photoshop" projectImage={Frens} projectImageAlt="medieval frens"  workNumber="13" textMode="Dark" />   
          </div>
      </Tab>
      <Tab eventKey="physical" title="Physical">
      <div className='row m-0'>
          <ColumnComponent title="" description="Illustration" tool="Colored Pencils" projectImage={Cd} projectImageAlt="cd colored"  workNumber="15" textMode="Light" />
          <ColumnComponent title="" description="Illustration" tool="Acrylic Paint" projectImage={Car} projectImageAlt="cart painted"  workNumber="14" textMode="Dark" />
          <ColumnComponent title="" description="Sculpture" tool="Modelling Clay" projectImage={Hape} projectImageAlt="hape sculpture"  workNumber="16" textMode="Light" />
          </div>
      </Tab>
  </Tabs>
        </div>
        </div>
       
      </div>
    </div>
    </>
)
}

export default Home
